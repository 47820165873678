<template>
  <div>
    <form-wizard
      color="#3e74c7"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Létrehozás"
      next-button-text="Tovább"
      back-button-text="Vissza"
      class="wizard-vertical mb-3"
      @on-complete="formSubmitted"
    >

      <!-- account datails tab -->
      <tab-content title="Fiók információk">
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Felhasználó adatai
            </h5>
            <small class="text-muted">
              Kérlek add meg a felhaszáló adatait illetve szerepkörét.
            </small>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Név"
              label-for="v-username"
            >
              <b-form-input
                id="v-username"
                v-model="name"
                placeholder="Szakállas Róbert"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="E-mail cím"
              label-for="v-email"
            >
              <b-form-input
                v-model="email"
                id="v-email"
                type="email"
                placeholder="szakallas.robert@debszoftver.hu"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Jelszó"
              label-for="v-password"
            >
              <b-form-input
                id="v-password"
                type="password"
                v-model="password"
                placeholder="W?9rK_cv"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Telefonszám"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                type="text"
                v-model="phone"
                placeholder="+36 30 111 1111"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <p>Engedélyek:</p>
          </b-col>
          <div style="margin-bottom:5px" v-for="(role, index) in roles" :key="index" :class="{ 'col-lg-4':role.value, 'col-lg-12': !role.value}">
            <div v-if="role.value" class="d-flex">
              <b-form-checkbox
                :id="'v-role-' + index"
                v-model="selected[index]"
                :value="role.value"
                :options="role.value"
              /> <label :for="'v-role-' + index">{{ role.text }}</label>
            </div>
            <div class="mt-2" v-else>
              {{ role.text }}
            </div>
          </div>
        </b-row>
        <b-row>
          <b-col class="mt-2" md="12">
            <p>Rendszer értesítések:</p>
          </b-col>
          <b-col class="d-flex" style="margin-bottom:5px" v-for="(notification, index) in notifications" :key="index" md="4">
            <b-form-checkbox
              :id="'v-notification-' + index"
              v-model="selectedNotification[index]"
              :value="notification.value"
              :options="notification.value"
            /> <label :for="'v-notification-' + index">{{ notification.text }}</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-2" md="12">
            <p>Viber értesítések:</p>
          </b-col>
          <b-col class="d-flex" style="margin-bottom:5px" v-for="(notification, index) in viber_notifications" :key="index" md="4">
            <b-form-checkbox
              :id="'viber-notification-' + index"
              v-model="selectedNotification[index]"
              :value="notification.value"
              :options="notification.value"
            /> <label :for="'viber-notification-' + index">{{ notification.text }}</label>
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>

  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      password: '',
      roles: [
        { text: 'HR' },
        { text: 'Felhasználók kezelése (szerkesztés, létrehozás)', value: 'user' },
        { text: 'Alvállalkozók kezelése (szerkesztés, létrehozás)', value: 'subcontractor' },
        { text: 'Dokumentumok elérése', value: 'documents_list' },
        { text: 'Szabadságok felületét eléri', value: 'freeday_menu' },
        { text: 'Alkalmazottak felületét eléri', value: 'employee_menu' },
        { text: 'Extra mezőket hozhat létre', value: 'extra_fields' },
        { text: 'Jelenléti íveket eléri', value: 'accounting' },
        { text: 'Riportok részt eléri', value: 'report' },
        { text: 'Építés vezető akinek van jogosultsága szabadnapot elbírálni', value: 'foreman_freeday' },
        { text: 'Vezető akinek van jogosultsága szabadnapot elbírálni', value: 'leader_freeday' },
        { text: 'Pénzügy' },
        { text: 'Levonások elérése kezelése', value: 'private_comment' },
        { text: 'Új levonás létrehozás', value: 'payments_create' },
        { text: 'Számlák feldolgozása', value: 'invoice_progress' },
        { text: 'Hitelezés kezelése (létrehozás/szerkesztés)', value: 'credit_menu' },
        { text: 'Rendelések' },
        { text: 'Rendelések menüpont', value: 'order_menu' },
        { text: 'Rendelés leadás', value: 'create_order' },
        { text: 'Minden rendelést elér', value: 'progress_order' },
        { text: 'Rendelést lehet hozzá rendelni (raktáros)', value: 'order_target' },
        { text: 'Rendeléshez tud ajánlatot feltölteni (árajánlatot tud csatolni a rendeléshez)', value: 'upload_offer' },
        { text: 'Rendeléshez elutasítására jogosultság', value: 'decline_offer' },
        { text: 'Rendelés kiszállítására jogosultság', value: 'delivery_offer' },
        { text: 'Rendelésel kapcsolatos számlák elérése', value: 'invoices_offer' },
        { text: 'Készlet feltöltés elbírálója lehet', value: 'storage_role' },
        { text: 'Készlet feltöltést adhat le', value: 'restocking_role' },
        { text: 'Általános' },
        { text: 'Bejelentkezés', value: 'dashboard' },
        { text: 'Feladatkezelő', value: 'todo' },
        { text: 'Összes feladat elérése', value: 'super-todo' },
        { text: 'Projektek kezelése', value: 'project' },
        { text: 'Ajánlat létrehozása', value: 'create_offer' },
        { text: 'Plusz munka elfogadása', value: 'extra_work' },
        { text: 'Árajánlat elfogadása', value: 'accept_order' },
        { text: 'Viber konfiguráció', value: 'viber' },
        { text: 'Jármű kezelés', value: 'car_menu' },
      ],
      notifications: [
        { text: 'Új feladatot kapott (email)', value: 'new_task' },
        { text: 'Kiadott feladat elkészült (email)', value: 'task_done' },
        { text: 'Lejárt projekt mérföldkövekről értesítés (csak ha építésvezető)', value: 'project_down' },
        { text: 'Lejárt projekt mérföldkövekről értesítés (mindről)', value: 'project_down_boss' },
        { text: 'Értesítés az új bejövő számlákról', value: 'invoice_notification' },
        { text: 'Értesítés az új belsős rendelésekről és státusz változásokról', value: 'order_inside' },
        { text: 'Értesítés az új külsős rendelésekről és státusz változásokról', value: 'order_outside' },
        { text: 'Értesítés az új ajánlatokról', value: 'offer_notification' },
        { text: 'Értesítés az új levonásokról', value: 'payment_notification' },
        { text: 'Értesítés az lejáró teljesítési igazolásokról', value: 'project_tig_notification' },
        { text: 'Értesítés az új bejövő rendeléshez tartozó árajánlatokról', value: 'new_order_offer' },
        { text: 'Új rendeléssel kapcsolatos számlákról üzenet', value: 'new_invices_offer_message' },
        { text: 'Bank garancia ügyintézés értesítés', value: 'invoice_notification_bank' },
        { text: 'Értesítés bejövő szabadságigényekről (építés vezető)', value: 'freeday_notification_foreman' },
        { text: 'Értesítés bejövő szabadságigényekről (vezető)', value: 'freeday_notification_leader' },
        { text: 'Értesítés a feldolgozott szabadságigényekről', value: 'freeday_notification_done' },
        { text: 'Alkalmazott gyereke betölti a 17. élet évét', value: 'became_an_adult' },
        { text: 'Orvosi alkalmassági lejárati értesítés', value: 'medical_notification' },
        { text: 'Munkavédelmi vizsga lejárati értesítés', value: 'osh_notification' },
        { text: 'Értesítés a lejáró jármű biztosításokról', value: 'car_down' },
        { text: 'Értesítés a lejáró extra alkalmazotti mezőkről', value: 'extra_notification' },
        { text: 'Értesítés a raktári feltöltésekről', value: 'order_storage' },
        { text: 'Értesítés minden projekt készlet túllépésről', value: 'boss_stock_overrun' },
        { text: 'Értesítés az olyan projekt készlet túllépésekről az építés vezetőnek', value: 'foreman_stock_overrun' },
      ],
      viber_notifications: [
        { text: 'Feladatokról értesítés', value: 'viber_task' },
        { text: 'Lejárt projekt mérföldkövekről értesítés (csak ha építésvezető)', value: 'viber_project_down' },
        { text: 'Lejárt projekt mérföldkövekről értesítés (mindről)', value: 'viber_project_down_boss' },
        { text: 'Értesítés az új bejövő számlákról', value: 'viber_invoice_notification' },
        { text: 'Értesítés az új belsős rendelésekről és státusz változásokról', value: 'viber_order_inside' },
        { text: 'Értesítés az új külsős rendelésekről és státusz változásokról', value: 'viber_order_outside' },
        { text: 'Értesítés az új ajánlatokról', value: 'viber_offer_notification' },
        { text: 'Értesítés az új levonásokról', value: 'viber_payment_notification' },
        { text: 'Értesítés az lejáró teljesítési igazolásokról', value: 'viber_project_tig_notification' },
        { text: 'Értesítés az új bejövő rendeléshez tartozó árajánlatokról', value: 'viber_new_order_offer' },
        { text: 'Új rendeléssel kapcsolatos számlákról üzenet', value: 'viber_new_invices_offer_message' },
        { text: 'Bank garancia ügyintézés értesítés', value: 'viber_invoice_notification_bank' },
        { text: 'Értesítés bejövő szabadságigényekről (építés vezető)', value: 'viber_freeday_notification_foreman' },
        { text: 'Értesítés bejövő szabadságigényekről (vezető)', value: 'viber_freeday_notification_leader' },
        { text: 'Értesítés a feldolgozott szabadságigényekről', value: 'viber_freeday_notification_done' },
        { text: 'Alkalmazott gyereke betölti a 17. élet évét', value: 'viber_became_an_adult' },
        { text: 'Orvosi alkalmassági lejárati értesítés', value: 'viber_medical_notification' },
        { text: 'Munkavédelmi vizsga lejárati értesítés', value: 'viber_osh_notification' },
        { text: 'Értesítés a lejáró jármű biztosításokról', value: 'viber_car_down' },
        { text: 'Értesítés a lejáró extra alkalmazotti mezőkről', value: 'viber_extra_notification' },
        { text: 'Értesítés a raktári feltöltésekről', value: 'viber_order_storage' },
        { text: 'Értesítés minden projekt készlet túllépésről', value: 'viber_boss_stock_overrun' },
        { text: 'Értesítés az olyan projekt készlet túllépésekről az építés vezetőnek', value: 'viber_foreman_stock_overrun' },
      ],
      selected: [],
      selectedNotification: [],
    }
  },
  methods: {
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Elküldve feldolgozásra',
          icon: 'LoaderIcon',
          variant: 'warning',
        },
      })
      axios({
        method: 'post',
        url: 'user/create',
        data: {
          name: this.name,
          email: this.email,
          password: this.password,
          role: this.selected,
          notification: this.selectedNotification,
          phone: this.phone,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Felhasználói fiók létrehozva',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.selected = []
        this.selectedNotification = []
        this.name = ''
        this.phone = ''
        this.email = ''
        this.password = ''
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: Object.values(error.response.data.errors)[0][0],
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style scoped>
  .flatpickr-input{
    display: none !important;
  }
</style>
